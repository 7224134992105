import { createContext } from 'react';

import { File } from '@models/file';

import {
  FormContextType,
  FieldCollection,
  listItem,
  metaField
} from './interfaces';

const FormContext = createContext<FormContextType>({
  currentStep: 1,
  startedForm: true,
  setStartedForm: null,
  loading: true,
  workflowId: '',
  workflow: null,
  primaryRole: ''
});

export default FormContext;
